<template>
  <g :fill="color" fill-rule="evenodd">
    <path
      d="M17.286 20.771A10.211 10.211 0 0 1 12 22.236c-5.661 0-10.25-4.586-10.25-10.243S6.339 1.75 12 1.75s10.25 4.586 10.25 10.243c0 1.982-.563 3.88-1.607 5.511a.75.75 0 1 0 1.263.809 11.69 11.69 0 0 0 1.844-6.32C23.75 5.507 18.49.25 12 .25S.25 5.507.25 11.993c0 6.485 5.26 11.743 11.75 11.743 2.164 0 4.244-.587 6.06-1.68a.75.75 0 0 0-.774-1.285z"
    />
    <path
      d="M18.523 20.704a.75.75 0 1 0-.474 1.423l4.714 1.57a.75.75 0 0 0 .949-.949l-1.572-4.71a.75.75 0 0 0-1.423.474l1.097 3.288-3.291-1.096zM8.857 11.172h6.286a.75.75 0 0 0 0-1.5H8.857a.75.75 0 0 0 0 1.5zM8.857 15.884H12a.75.75 0 1 0 0-1.5H8.857a.75.75 0 0 0 0 1.5z"
    />
  </g>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
